String.prototype.string = function(len) {
  var s = "",
    i = 0;
  while (i++ < len) {
    s += this;
  }
  return s;
};

String.prototype.zf = function(len) {
  return "0".string(len - this.length) + this;
};

Number.prototype.zf = function(len) {
  return this.toString().zf(len);
};

export default {
  format(date, format) {
    if (!date.valueOf()) return " ";

    var weekKorName = [
      "일요일",
      "월요일",
      "화요일",
      "수요일",
      "목요일",
      "금요일",
      "토요일",
    ];

    var weekKorShortName = ["일", "월", "화", "수", "목", "금", "토"];

    var weekEngName = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    var weekEngShortName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    return format.replace(
      /(yyyy|yy|MM|M|dd|D|KS|KL|ES|EL|HH|hh|mm|ss|a\/p)/gi,
      function($1) {
        switch ($1) {
          case "yyyy":
            return date.getFullYear(); // 년 (4자리)

          case "yy":
            return (date.getFullYear() % 1000).zf(2); // 년 (2자리)

          case "MM":
            return (date.getMonth() + 1).zf(2); // 월 (2자리)

          case "M": {
            let m = (date.getMonth() + 1).zf(2);
            m = (m.slice(0,1) == '0') ? m.slice(1) : m;  
            return m;} // 월 (2자리 에서 0 제거)

          case "dd":
            return date.getDate().zf(2); // 일 (2자리)

          case "D": {
            let d = date.getDate().zf(2);
            d = (d.slice(0,1) == '0') ? d.slice(1) : d;  
            return d;} // 일 (2자리 에서 0 제거)

          case "KS":
            return weekKorShortName[date.getDay()]; // 요일 (짧은 한글)

          case "KL":
            return weekKorName[date.getDay()]; // 요일 (긴 한글)

          case "ES":
            return weekEngShortName[date.getDay()]; // 요일 (짧은 영어)

          case "EL":
            return weekEngName[date.getDay()]; // 요일 (긴 영어)

          case "HH":
            return date.getHours().zf(2); // 시간 (24시간 기준, 2자리)

          case "hh":
            var h = date.getHours() % 12;
            return (h ? h : 12).zf(2); // 시간 (12시간 기준, 2자리)

          case "mm":
            return date.getMinutes().zf(2); // 분 (2자리)

          case "ss":
            return date.getSeconds().zf(2); // 초 (2자리)

          case "a/p":
            return date.getHours() < 12 ? "오전" : "오후"; // 오전/오후 구분

          default:
            return $1;
        }
      }
    );
  },

  getWorldTime(tzOffset) {
    // 24시간제
    var now = new Date();
    var tz =
      now.getTime() + now.getTimezoneOffset() * 60000 + tzOffset * 3600000;
    now.setTime(tz);

    return now;
  },
  addDays(date, days) {
    const clone = new Date(date);
    clone.setDate(date.getDate() + days)
    return clone;
  },
  addMonth(date, month, flag) { // flag == true 이면 날자 하루 쉬프트
    // month달 후의 1일
    let addMonthFirstDate = new Date(
      date.getFullYear(),
      date.getMonth() + month,
      1
    );
    
    // month달 후의 말일
    let addMonthLastDate = new Date(
      addMonthFirstDate.getFullYear(),
      addMonthFirstDate.getMonth() + 1
      , 0
    );
    
    let result = addMonthFirstDate;
    if(date.getDate() > addMonthLastDate.getDate()) 
      {
        result.setDate(addMonthLastDate.getDate());
      } 
    else 
      {
        result.setDate(date.getDate());
      }
    
    if (flag) result.setDate(result.getDate() - ( month / Math.abs(month)) ); 
    
    return result;
  }
};
