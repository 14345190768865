<template>
  <v-container v-show="isreload">
    <v-card rounded-pill width="100%" outlined class="mx-auto pa-1 mb-2" >
      <v-layout wrap>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 200px;" 
          id="farm" 
          ref="farm" 
          label="농장/사업장✽" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeFarm()"
          @focus="comboFarms()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                 {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 200px;"
          id="building" 
          ref="building" 
          label="축사/건물/구역" 
          v-model="building"
          :items="buildingItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          placeholder="선택않으면 농장/사업장전체를 모니터합니다"
          item-text="name"
          return-object
          @change="changeBuilding()"
          @focus="comboBuildings()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                 {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-btn class="elevation-5 ma-1 rounded-pill normal-btn" style="height: 40px; width: 110px"
          id="refreshList" 
          :loading="loading" 
          ref="refreshList" 
          @click="refreshList()">
          <img src="@/assets/icon_refresh.svg" alt="새로고침" />
          새로고침
        </v-btn>
      </v-layout>
    </v-card>
    <v-card outlined class="mb-2" v-for="building in building_list" :key="building.building_cd">
        <span class="text_building">{{ building.building_name }}</span>
        <div id="cardRoomInfo">
          <div v-for="room in building.room_list" :key="room.room_code">
            <div id="roomBox" :class="room.color" @click="clickRoom(building,room)">
              <div id="textArea">
                <div class="text_status" :class="room.statuscolor" >
                  {{ room.controlstatus }}
                </div>
                <div class="text_date" :class="{ color_black: room.color === 'white' }">
                  {{ room.enter_date }}
                </div>
                <div class="text_name" >
                  <span class="text_name" :class="{ color_black: room.color === 'white' }">{{ room.room_name.substring(0,10) }}</span>
                </div>

                <div class="box_temp_count">
                  <img :src="icon_temperature" v-show="room.color === 'white'" />
                  <img
                    :src="icon_temperature_white"
                    v-show="room.color !== 'white'"
                  />
                  <div class="spacer"></div>
                  <span
                    class="text_number"
                    :class="{ color_black: room.color === 'white' }"
                  >
                    {{ room.temp }}
                  </span>
                  <span
                    class="text_number_unit"
                    :class="{ color_black: room.color === 'white' }"
                    >℃</span
                  >
                </div>
                <div class="box_temp_count">
                  <img :src="icon_pig_count" v-show="room.color === 'white'" />
                  <img :src="icon_pig_count_white" v-show="room.color !== 'white'" />
                  <div class="spacer"></div>
                  <span
                    class="text_number"
                    :class="{ color_black: room.color === 'white' }"
                  >
                    {{ room.pig_cnt }}
                  </span>
                  <span
                    class="text_number_unit"
                    :class="{ color_black: room.color === 'white' }"
                    >두</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
    </v-card>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import VueCookies from "vue-cookies";
import dateUtil from "@/utils/custom/dateProperty.js";
// import common from "@/utils/custom/common";

export default {
  name: "CardRoomInfo",
  // props: {
  //   room_list: {
  //     type: Array,
  //     required: true,
  //   },
  // },
  data() {
    return {
      icon_temperature: require("@/assets/barometer/icon_temperature.svg"),
      icon_pig_count: require("@/assets/barometer/icon_pig_count.svg"),
      icon_temperature_white: require("@/assets/barometer/icon_temperature_white.svg"),
      icon_pig_count_white: require("@/assets/barometer/icon_pig_count_white.svg"),

      isreload: false,
      building_list: [],
      farm : {},
      farmItems : [],
      building : {},
      buildingItems : [],
      loading: false,

    };
  },
  async created() {
    let url = new URL(window.location.href);
    // URLSearchParams 객체
    let urlParams = url.searchParams;
    // URLSearchParams.get()
    let cc = urlParams.get('cc');
    let cn = urlParams.get('cn');
    let fc = urlParams.get('fc');
    let fn = urlParams.get('fn');
    
    if (fc) {     // URL파라미터 벋으면 페이지 이동시 URL파라미터 사라지지 않음, 방지를 위한 리로드 : 연구 개선 필요
      // console.log("farm",cc,cn,fc,fn);
      sessionStorage.setItem("paramInfo",JSON.stringify({
          farm:{company_cd: cc, company_name: cn, code: fc, name: fn},
          building:{},})
      );
      history.replaceState({}, null,location.pathname);
      location.reload();
    } else {
      Apis.pageCount({
        page_name: this.$route.name,
        url: this.$route.path,
        division: "pigro",
      },() => {  // 정상처리
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("pageCount 호출 오류",err);
      });

      this.isreload = true;
      await this.comboFarms();
      await this.comboBuildings();

      let cookie_info = VueCookies.get("cardroom_info");

      let paramInfo = JSON.parse(sessionStorage.getItem("paramInfo"));
      if (paramInfo) {
        // console.log("파람",cookie_info);
        cookie_info = paramInfo;
        sessionStorage.removeItem("paramInfo");
      }

      if (cookie_info) {
        // console.log("쿠키",cookie_info);
        this.farm = cookie_info.farm || {};
        this.building = cookie_info.building || {};
      } else {
        this.farm = this.farmItems[0];
      }
      this.refreshList();
    }
  },

  methods: {
    refreshList() {
      this.$store.commit("resMessage","");
      if (!this.farm || !this.farm.code ) {
        alert("축사/건물/구역 또는 농장/사업장을 선택하세요");
        this.$refs.building.focus();
        return;
      }
      this.loading = true;
      Apis.farmSummaryView({
        farm_cd: this.farm && this.farm.code,
        building_cd: this.building && this.building.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
      } ,(res) => {  // 정상처리
        this.$store.commit("resMessage",res.message);
        this.building_list = res.data;
        // console.log(res.data);
        this.loading = false;
        let cookie_info = {farm: this.farm, building: this.building };
        VueCookies.set("cardroom_info" ,cookie_info,"30d");
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("farmSummaryView 호출 오류",err);
        this.loading = false;
      })
    },
    clickRoom(building,room) {
      console.log("click",building,room);
      this.$router.push({
        name: "Barometer",
        params: { 
          company: {code: building.company_cd, name: building.company_name},
          farm: {code: building.farm_cd, name: building.farm_name},
          building: {code: building.building_cd, name: building.building_name},
          room: {code: room.room_cd, name: room.room_name + " " + building.farm_name },
          }
      });
    },
    async comboFarms() {
      await Apis.comboFarms({
        company_cd: "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
            // alert(err);
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.building = {};
      }
      this.buildingItems = [];
    },
    async comboBuildings() {
      await Apis.comboBuildings({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.buildingItems = [];
            for (let i in res.data) {
            this.buildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.building && this.building.farm_cd) {
        this.farm = {code: this.building.farm_cd, name: this.building.farm_name};
      }
    },

  },
};
</script>

<style lang="scss" scoped>
#cardRoomInfo {
  position: relative;
  left: 20px;
  width: calc(100% - 10px);
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  // background-color: teal;
}

#roomBox {
  width: 165px;
  height: 165px;
  margin: 2px 35px 20px 0;
  border: solid 1px #57715d !important;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  // background-color: black;
}

#textArea {
  // border: solid 1px #726f6f;
  margin: 0px 12px 0px 12px;
  resize: none;
  // background-color: tomato;
}

.text_status {
  position: relative;
  left: 2px;
  font-size: 10px !important;
  font-weight: 500 !important;
  letter-spacing: -0.39px;
  text-align: right !important;
  color: #a3a3a3;
}

.text_date {
  position: relative !important;
  left: 2px;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: -0.39px;
  text-align: left !important;;
  color: white;
}

.text_name {
  position: relative;
  left: 0px;
  font-size: 18px !important;
  font-weight: bolder !important;
  letter-spacing: -2.0px;
  text-align: left !important;
  color: white;
  margin-bottom: 5px;
}
.text_building {
  position: relative;
  left: 15px;
  font-size: 18px !important;
  font-weight: bold !important;
  letter-spacing: -0.6px;
  text-align: left !important;
  color: #3a4f3f;
}

#textArea > div {
  font-size: 20px;
  font-weight: 500 !important;
  letter-spacing: -0.6px;
  text-align: right;
  margin-right: 3px;
  color: white;
}
.text_number {
  font-size: 20px;
  letter-spacing: -0.78px;
}

.text_number_unit {
  height: 35px;
  font-size: 15px;
  margin-left: 5px;
  line-height: 42px;
}

.white {
  border: solid 2px #3a4f3f;
}
.green {
  background-color: #3a4f3f !important;
}
.red {
  background-color: #88191a !important;
}

.color_black {
  color: #3a4f3f !important;
}

.color_gray {
  color: #a3a3a3 !important;
}

.color_lightgray {
  color: #c5c5c5 !important;
}

.color_auto {
  color: #03c097 !important;
}

.color_manual {
  color: #a09f9f !important;
}

.box_temp_count {
  display: flex;
  align-items: center;
  // background-color: coral;
}
.normal-btn{
  align-content: center !important;
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}

@media screen and (max-width: 768px){
  #cardRoomInfo{
  left:20px;
  justify-content: center;
  }
}
.items {
  color: #a3a1a1;
  font-size: 14px;
  // font-weight: bold;
  text-align: left;
  letter-spacing: -1.2px;
}


</style>
